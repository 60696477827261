import React from "react"

import {
  Container,
  Wrapper,
  Item,
  ImageBox,
  TextBox,
  Title,
  Text,
  More,
} from "./styles"

import { Waves } from "components/Waves"

import {
  Benefits,
  Selection,
  FreeValuation,
  TechHelp,
} from "../../../components/Icons"

const data_offer = [
  {
    title: "Darmowa wycena",
    text:
      "Przede wszystkim zależy nam na zadowoleniu naszych klientów, dlatego dostaniesz od nas darmową wycenę projektu. Dbamy, aby zadowolony był również twój portfel!",
    icon: <FreeValuation />,
  },
  {
    title: "Jak dobrać klimatyzacje?",
    text:
      "Nasi specjaliści pomogą Ci w wyborze najlepszej klimatyzacji. Powiedz nam czego potrzebujesz, a my znajdziemy dla Ciebie idealne rozwiązanie.",
    icon: <Selection />,
  },
  {
    title: "Wsparcie techniczne",
    text:
      "Gwarantujemy wsparcie techniczne naszym klientom. Boisz się, że twoja klimatyzacja przestanie działać? Zadzwoń do nas, a rozwiążemy problem. ",
    icon: <TechHelp />,
  },
  {
    title: "Korzyści posiadania klimatyzacji",
    text:
      "Zadbaj o swój komfort nie tylko w upalne dni. Klimatyzacja utrzyma twoje pomieszczenie w temperaturze, która będzie dla Ciebie odpowiednia. To nie tylko komfort, a również czyste i świeże powietrze!",
    icon: <Benefits />,
  },
]

export const OfferIcons = () => {
  return (
    <Container>
      <div style={{ position: "relative" }}>
        <Waves />
      </div>
      <Wrapper>
        {data_offer.map((data, i) => (
          <Item>
            <ImageBox>{data.icon}</ImageBox>
            <TextBox>
              <Title>{data.title}</Title>
              <Text>{data.text}</Text>
            </TextBox>
          </Item>
        ))}
      </Wrapper>
    </Container>
  )
}
