import styled from "styled-components"
import { PageWrapper, OverflowWrapper } from "shared/Styles/Wrappers"
import { MediumHeader, Paragraph } from "components/Typography"
import { CommonLink } from "components/Buttons"
import { Link } from "gatsby"

export const CustomOverflowWrapper = styled.section`
  background: white;
  min-height: 100vh;
  height: unset;
  overflow: visible;
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (max-width: 650px) {
    display: block;
    padding-top: 30px;
    height: unset;
  }
  &:before {
    content: " ";
    position: absolute;
    right: 0%;
    top: 0;
    height: 100vh;
    width: 60vw;
    background: ${({ theme }) => theme.color.white};
    @media screen and (max-width: 650px) {
      height: 375px !important;
      left: 0;
      right: unset;
    }
  }
`

export const ServiceWrapper = styled(PageWrapper)`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    align-items: flex-start;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    position: relative;
  }
`
export const ImageWrapper = styled.div`
  width: 55%;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    width: 50%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    height: 310px;
  }
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  &:after {
    position: absolute;
    content: " ";
    height: 18px;
    width: 100%;
    background: ${({ theme }) => theme.color.secondary};
    z-index: 2;
    bottom: 0;
    left: 0;
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.phoneBig}) {
      height: 10px;
    }
  }
`
export const TextWrapper = styled.div`
  margin-left: 80px;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    svg {
      height: 90px;
      margin-top: 0 !important;
    }
    margin-left: 40px;
    & > * {
      margin: 16px 0;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    svg {
      height: 70px;
    }
  }
  @media screen and (max-width: 650px) {
    margin-left: 0;
    padding: 85px 30px 55px 30px;
    max-width: unset;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phoneSmall}) {
    padding: 65px 10px 35px 10px;
  }
  .spacing-text {
    margin: 20px 0;
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletBig}) {
      margin: 16px 0;
    }
  }
`
export const ConsultancyHeader = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    margin: 0;
  }
  @media screen and (max-width: 650px) {
    flex-direction: row;
    align-items: center;
    ${MediumHeader} {
      font-size: 22px;
    }
    svg {
      margin-right: 20px !important;
    }
  }
`
export const ServiceLink = styled(Link)`
    ${CommonLink}
    color: ${({ theme }) => theme.color.primary};
    width: 55px;
    cursor: pointer;
    text-decoration: none;
    &:hover{
        color: ${({ theme }) => theme.color.secondary};
    }
`
export const CardContainer = styled(PageWrapper)`
  display: flex;
  justify-content: space-evenly;
  height: 640px;
  align-items: center;
  position: relative;
  z-index: 5;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    height: 700px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    height: unset;
    flex-direction: column;
  }
`
export const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  text-align: center;
  max-width: 290px;
  width: 33%;
  height: 480px;
  ${Paragraph} {
    margin: 16px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    ${Paragraph} {
      margin: 12px 0;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    padding: 20px 15px;
    height: 520px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    height: unset;
    width: 100%;
    max-width: 300px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phoneSmall}) {
    padding-left: 10px;
    padding-right: 10px;
  }
`
export const FoldableCardHeader = styled.div`
  ${MediumHeader},svg {
    margin: 16px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    svg {
      width: 95px;
      height: 95px;
    }
    ${MediumHeader},svg {
      margin: 12px 0;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    svg {
      margin: 0;
      height: 70px;
      width: 70px;
      flex-shrink: 0;
    }
    ${MediumHeader} {
      margin: 0 0 0 8px;
      font-size: 22px;
    }
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 12px 0 0 0;
  }
`
