import styled from "styled-components"
import { Link } from "gatsby"
import { PageWrapper } from "shared/Styles/Wrappers"

export const Container = styled.div`
  margin: 100px 0;
  @media screen and (max-width: 1441px) {
    margin: 60px 0;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    margin: 30px 0 60px 0;
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const Wrapper = styled(PageWrapper)`
  display: flex;
  flex-wrap: wrap;
`

export const Item = styled.div`
  width: 50%;
  padding: 20px;
  margin-bottom: 60px;
  display: flex;
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`

export const ImageBox = styled.div`
  width: 110px;
  height: 110px;
  margin-right: 40px;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
  @media screen and (max-width: 1441px) {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`

export const TextBox = styled.div`
  max-width: 305px;
`
export const Title = styled.div`
  font-size: 24px;
  line-height: 27px;
  font-weight: 700;
  height: 40px;
  @media screen and (max-width: 1441px) {
    font-size: 20px;
  }
`
export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin: 30px 0;
  @media screen and (max-width: 1441px) {
    font-size: 14px;
    margin: 20px 0;
  }
`

export const More = styled(Link)`
  color: ${props => props.theme.color.primary};
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  &:after {
    content: ">";
    display: inline-block;
    margin-left: 5px;
  }
`
