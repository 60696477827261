import React from "react"

import Image from "gatsby-image"
import { useSliderPhotos } from "./useSliderPhotos"

const useSliderData = () => {
  const images = useSliderPhotos()
  return [
    {
      image: <Image fluid={images.slide_1.childImageSharp.fluid} />,
    },
    {
      image: <Image fluid={images.slide_2.childImageSharp.fluid} />,
    },
    // {
    //   image: <Image fluid={images.slide_3.childImageSharp.fluid} />,
    // },
    // {
    //   image: <Image fluid={images.slide_4.childImageSharp.fluid} />,
    // },
  ]
}

export { useSliderData }
