import { graphql, useStaticQuery } from "gatsby"

const useGalleryPhotos = () => {
  const images = useStaticQuery(graphql`
    query {
      gallery_1: file(relativePath: { eq: "images/Offer/gal1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery_2: file(relativePath: { eq: "images/Offer/gal2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return images
}

export { useGalleryPhotos }
