import styled from 'styled-components'

export const WavesWrapper = styled.div`
    position: absolute;
    width: 100vw;
    z-index: 0;
    left: ${(props)=>props.left?props.left:'0'};
    top: ${(props)=>props.top?props.top:'0'};
    svg{
        width:100%;
    }
    svg:first-child{
        position:absolute;
        top: 0;
        transform: matrix(1, -0.01, 0.01, 1, 0, 0);
    }
    svg:nth-child(2){
        position:absolute;
        top: 80px;
        transform: rotate(0.26deg);
    }
    svg:last-child{
        position:absolute;
        top: 160px;
        transform: matrix(1, -0.02, 0.01, 1, 0, 0);
    }
    @media screen and (max-width: 650px){
        display: none;
    }
`