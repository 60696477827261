import React from "react"

import { Swiper, SwiperSlide } from "swiper/react"
import { useSliderData } from "../../../shared/Hooks/AboutSlider/useSliderData"

import { ArrowLong } from "components/Icons"

import {
  BlueBox,
  Container,
  Wrapper,
  Column,
  TextBox,
  Title,
  Text,
  NavigationBox,
  ArrowWrapper,
} from "./styles"

const data_text = {
  title: "Czyszczenie filtrów jest bardzo ważne",
  text:
    "Zabrudzony system klimatyzacji może zaszkodzić stając się siedliskiem bakterii, grzybów i pasożytów. Zadbaj o to by powietrze było zdrowe i czyste.",
}

export const OfferSlider = () => {
  const images = useSliderData()
  return (
    <Container>
      <Wrapper>
        <Column>
          <BlueBox>
            <TextBox>
              <Title>{data_text.title}</Title>
              <Text>{data_text.text}</Text>
            </TextBox>
            <NavigationBox>
              <ArrowWrapper className={`swiper-prev  reverse`}>
                <ArrowLong width={"41px"} height={"20px"} color={"#fff"} />
              </ArrowWrapper>
              <ArrowWrapper className={`swiper-next`}>
                <ArrowLong width={"41px"} height={"20px"} color={"#fff"} />
              </ArrowWrapper>
            </NavigationBox>
          </BlueBox>
        </Column>
        <Column>
          <Swiper
            loop={true}
            spaceBetween={30}
            loopAdditionalSlides={2}
            navigation={{
              nextEl: ".swiper-next",
              prevEl: ".swiper-prev",
            }}
          >
            {images.map((image, i) => (
              <SwiperSlide key={i}>{image.image}</SwiperSlide>
            ))}
          </Swiper>
        </Column>
      </Wrapper>
    </Container>
  )
}
