import React from "react"

import Image from "gatsby-image"
import { useGalleryPhotos } from "./useGalleryPhotos"

const useGalleryData = () => {
  const images = useGalleryPhotos()
  return [
    {
      image: <Image fluid={images.gallery_1.childImageSharp.fluid} />,
    },
    {
      image: <Image fluid={images.gallery_2.childImageSharp.fluid} />,
    },
  ]
}

export { useGalleryData }
