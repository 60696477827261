import styled from "styled-components"

export const TextBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  line-height: 1.3;
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    line-height: 1.5;
  }
`

export const Title = styled.h3`
  color: #fff;
  font-size: 36px;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    font-size: 26px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    font-size: 18px;
  }
`

export const Phone = styled.a`
  display: inline-block;
  color: ${props => props.theme.color.secondary};
  font-size: 56px;
  text-decoration: none;
  font-weight: 700;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 100%;
    height: 4px;
    background-color: ${props => props.theme.color.secondary};
    opacity: 0;
    transition: 0.2s all ease;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    font-size: 40px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    font-size: 24px;
  }
`
