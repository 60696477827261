import React from "react"

import {
  Wrapper,
  GalleryWrapper,
  GalleryItem,
  GalleryTitle,
  ImageBox,
} from "./styles"

import { useGalleryData } from "../../../shared/Hooks/OfferGallery/useGalleryData"

const data_gallery = {
  title: "Filtry przed i po czyszczeniu",
}

export const Gallery = () => {
  const images = useGalleryData()
  return (
    <Wrapper>
      <GalleryTitle>{data_gallery.title}</GalleryTitle>
      <GalleryWrapper>
        {images.map((image, i) => (
          <GalleryItem key={i}>
            <ImageBox>{image.image}</ImageBox>
          </GalleryItem>
        ))}
      </GalleryWrapper>
    </Wrapper>
  )
}
