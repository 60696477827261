import React from "react"
import { WavesWrapper } from "./styles"

export const Waves = ({ top, left }) => {
  return (
    <WavesWrapper top={top} left={left}>
      <svg
        width="1920"
        height="539"
        viewBox="0 0 1920 539"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-102.425 532.6C-24.8402 489.551 194.323 422.38 450.3 498.091C770.271 592.729 1204.64 520.334 1414.92 276.007C1625.2 31.6798 1921.15 -6.33826 2078.74 1.51851"
          stroke="#D9D9D9"
        />
      </svg>

      <svg
        width="1920"
        height="539"
        viewBox="0 0 1920 539"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-102.425 532.6C-24.8402 489.551 194.323 422.38 450.3 498.091C770.271 592.729 1204.64 520.334 1414.92 276.007C1625.2 31.6798 1921.15 -6.33826 2078.74 1.51851"
          stroke="#D9D9D9"
        />
      </svg>

      <svg
        width="1920"
        height="539"
        viewBox="0 0 1920 539"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-102.425 532.6C-24.8402 489.551 194.323 422.38 450.3 498.091C770.271 592.729 1204.64 520.334 1414.92 276.007C1625.2 31.6798 1921.15 -6.33826 2078.74 1.51851"
          stroke="#D9D9D9"
        />
      </svg>
    </WavesWrapper>
  )
}
