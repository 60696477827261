import React from "react"
import { MainSection } from "../sections/MainSection"
import { ContactBaner } from "../sections/Offer/ContactBaner"
import { Consultancy } from "sections/Offer/Consultancy"
import { Service } from "sections/Offer/Service"
import { Gallery } from "../sections/Offer/Gallery"
import { Assembly } from "sections/Offer/Assembly"
import { OfferSlider } from "../sections/Offer/OfferSlider"
import { OfferIcons } from "../sections/Offer/OfferIcons"
import SEO from "components/seo"

const OfferPage = () => {
  return (
    <>
      <SEO title="oferta - szeroki wybór klimatyzatorów" />
      <MainSection>
        <Consultancy id="doradztwo" />
      </MainSection>
      <OfferIcons />
      <Assembly id="montaz" />
      <Service id="serwis" />
      <OfferSlider />
      <Gallery />
      <ContactBaner />
    </>
  )
}

export default OfferPage
