import styled from "styled-components"

import { PageWrapper } from "shared/Styles/Wrappers"

export const Container = styled.div`
  background: #fafafa;
  padding: 150px 0;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50% - 50px);
    height: 100%;
    background-color: #fafafa;
    z-index: 2;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    padding: 70px 0;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    padding: 50px 0;
    &:before {
      display: none;
    }
  }
`

export const Wrapper = styled(PageWrapper)`
  display: flex;
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    max-width: 850px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  width: 50%;
  &:nth-child(1) {
    width: calc(50% - 50px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &:nth-child(2) {
    width: calc(50% + 50px);
    .swiper-container {
      overflow: visible;
      .swiper-slide {
        position: relative;
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 15px;
          width: 100%;
          background-color: ${props => props.theme.color.secondary};
          transition: 0.4s all ease;
          opacity: 0;
        }
      }
      .swiper-slide-active {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phoneBig}) {
    width: 100% !important;
  }
`

export const TextBox = styled.div`
  max-width: 260px;
`
export const Title = styled.div`
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: 700;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    font-size: 26px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    font-size: 18px;
  }
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    font-size: 14px;
  }
`

export const NavigationBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
`

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  svg {
    path {
      transition: 0.2s all ease;
    }
    @media screen and (max-width: ${props =>
        props.theme.breakpoints.phoneBig}) {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    svg {
      path {
        stroke: ${props => props.theme.color.secondary};
      }
    }
  }
  &:nth-child(1) {
    margin-right: 20px;
  }
  &.reverse {
    transform: rotate(180deg);
  }
`

export const ImageBox = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

export const BlueBox = styled.div`
  background-color: ${props => props.theme.color.primary};
  color: #fff;
  padding: 50px 40px 50px 90px;
  position: relative;
  z-index: 3;
  width: 100%;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    padding: 50px 30px 50px 40px;
  }
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    padding: 50px 15px 50px 30px;
  }
`
