import React from "react"

import { StyledOverflowHeader } from "sections/About/TestimonialsSlider/styles.js"

import { TextBox, Title, Phone } from "./styles"

const data_contanct_baner = {
  text_1: "Masz pytania?",
  text_2: "Nie wiesz od czego zacząć?",
  text_3: "Zadzwoń tel:",
  phone: "572 185 689",
}

export const ContactBaner = () => {
  return (
    <StyledOverflowHeader>
      <TextBox>
        <Title>{data_contanct_baner.text_1}</Title>
        <Title>{data_contanct_baner.text_2}</Title>
        <Phone href={`tel:${data_contanct_baner.phone}`}>
          {data_contanct_baner.text_3} {data_contanct_baner.phone}
        </Phone>
      </TextBox>
    </StyledOverflowHeader>
  )
}
