import React from "react"
import { MediumHeader, Paragraph } from "components/Typography"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import {
  ServiceWrapper,
  ImageWrapper,
  TextWrapper,
  CustomOverflowWrapper,
  ConsultancyHeader,
  ServiceLink,
  CardContainer,
  ServiceCard,
  FoldableCardHeader,
} from "./styles"
import { Waves } from "components/Waves"

export const Service = ({ id }) => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/Offer/image 41.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 668, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <CustomOverflowWrapper id={id}>
        <Waves top="40vh" />
        <ServiceWrapper>
          <ImageWrapper>
            <Img fluid={image.file.childImageSharp.fluid} />
          </ImageWrapper>
          <TextWrapper>
            <ConsultancyHeader>
              <svg
                width="119"
                height="117"
                viewBox="0 0 119 117"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.2101 66.3278V85.904"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M34.8582 73.6688L28.2415 80.2758C27.7514 80.7652 27.5063 81.2546 27.5063 81.9887V89.0851C27.5063 90.064 27.0162 91.0428 26.0359 91.2875L7.6562 98.6286C4.71544 99.8521 3 102.544 3 105.48V112.821"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M34.8582 83.457L50.5422 90.064C51.5225 90.5534 52.0126 91.2875 52.0126 92.2663V105.48"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M56.9138 73.6688H54.9533C51.5225 73.6688 47.8465 72.4453 44.9057 69.7536C44.9057 69.7536 38.289 63.8807 37.0637 61.9231C35.5933 59.9654 34.8582 54.0926 34.8582 51.4008V36.9633"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M71.6178 66.3278V85.904"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M78.9697 83.457L63.2856 90.064C62.3054 90.5534 61.8152 91.2875 61.8152 92.2663V105.48"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M56.9139 73.6688H58.8744C62.3053 73.6688 65.9813 72.4453 68.922 69.7536C68.922 69.7536 75.5388 63.8807 76.7641 61.9231C78.2345 59.9654 78.9696 53.8479 78.9696 51.4008V36.9633"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M52.0127 19.8341V2.70483H61.8152V19.8341"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M88.772 36.9633H25.0556"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M44.6608 14.9401V5.15192C37.3089 7.59895 29.957 14.6953 29.957 24.7282V27.1752L27.5063 29.6223V32.0693"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M69.167 14.9401V5.15192C76.5189 7.59895 83.8708 14.6953 83.8708 24.7282V27.1752L86.3214 29.6223V32.0693"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
                <path
                  d="M113.769 77.584L105.191 86.1486L98.3296 79.2969L106.907 70.7323C103.476 67.3064 95.879 67.7958 91.2228 72.4452C87.7919 75.8711 86.0764 80.5204 88.282 85.4145C89.0172 87.1274 88.5271 89.085 87.0567 90.5533L72.3529 105.235C70.6375 106.948 70.1473 109.64 71.6177 111.598C73.3332 114.045 77.0091 114.289 78.9696 112.332L93.9185 97.405C95.1438 96.1814 97.1043 95.4473 99.0648 96.1814C103.966 98.1391 108.867 96.4261 112.053 93.245C116.709 88.5956 117.199 81.2545 113.769 77.584Z"
                  stroke="#1371FB"
                  strokeWidth="5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
              </svg>
              <MediumHeader className={"spacing-text"}>Serwis</MediumHeader>
            </ConsultancyHeader>
            <Paragraph className={"spacing-text"}>
              Nieserwisowana klimatyzacja może stać się siedliskiem bakterii
              oraz wirusów. Aby zamiast pomagać, nie&nbsp;zaczęła szkodzić
              należy ją&nbsp;regularnie serwisować.
            </Paragraph>
            <Paragraph>
              Klimalab zapewnia regularną konserwację jej&nbsp;systemów, co&nbsp;wydłuża
              jej żywotność i&nbsp;wydajność oraz zmniejsza zużycie energii.
            </Paragraph>
          </TextWrapper>
        </ServiceWrapper>
      </CustomOverflowWrapper>
      <CardContainer>
        <ServiceCard>
          <FoldableCardHeader>
            <svg
              width="104"
              height="104"
              viewBox="0 0 104 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M88.7697 15.2303C78.948 5.40902 65.8897 0 52 0C38.1103 0 25.052 5.40902 15.2303 15.2303C5.40902 25.052 0 38.1103 0 52C0 65.8897 5.40902 78.948 15.2303 88.7697C25.052 98.591 38.1103 104 52 104C65.8897 104 78.948 98.591 88.7697 88.7697C98.591 78.948 104 65.8897 104 52C104 38.1103 98.591 25.052 88.7697 15.2303ZM84.4606 84.4606C75.79 93.1312 64.262 97.9062 52 97.9062C39.738 97.9062 28.21 93.1312 19.5394 84.4606C10.8688 75.79 6.09375 64.262 6.09375 52C6.09375 39.738 10.8688 28.21 19.5394 19.5394C28.21 10.8688 39.738 6.09375 52 6.09375C64.262 6.09375 75.79 10.8688 84.4606 19.5394C93.1312 28.21 97.9062 39.738 97.9062 52C97.9062 64.262 93.1312 75.79 84.4606 84.4606Z"
                fill="#1371FB"
              />
              <path
                d="M61.5992 42.4008V29H42.4008V42.4008H29V61.5992H42.4008V75H61.5992V61.5992H75V42.4008H61.5992ZM70.448 57.0471H57.0472V70.4478H46.9531V57.0471H33.5523V46.9529H46.9531V33.5522H57.0472V46.9529H70.448V57.0471Z"
                fill="#1371FB"
              />
            </svg>

            <MediumHeader>Zadbaj o zdrowie</MediumHeader>
          </FoldableCardHeader>
          <Paragraph>
            Niesprawny system klimatyzacji może zaszkodzić stając się
            siedliskiem bakterii, grzybów i pasożytów. Zadbaj o to by powietrze
            było zdrowe i czyste.
          </Paragraph>
        </ServiceCard>
        <ServiceCard>
          <FoldableCardHeader>
            <svg
              width="104"
              height="104"
              viewBox="0 0 104 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M88.7697 15.2303C78.948 5.40902 65.8897 0 52 0C38.1103 0 25.052 5.40902 15.2303 15.2303C5.40902 25.052 0 38.1103 0 52C0 65.8897 5.40902 78.948 15.2303 88.7697C25.052 98.591 38.1103 104 52 104C65.8897 104 78.948 98.591 88.7697 88.7697C98.591 78.948 104 65.8897 104 52C104 38.1103 98.591 25.052 88.7697 15.2303ZM84.4606 84.4606C75.79 93.1312 64.262 97.9062 52 97.9062C39.738 97.9062 28.21 93.1312 19.5394 84.4606C10.8688 75.79 6.09375 64.262 6.09375 52C6.09375 39.738 10.8688 28.21 19.5394 19.5394C28.21 10.8688 39.738 6.09375 52 6.09375C64.262 6.09375 75.79 10.8688 84.4606 19.5394C93.1312 28.21 97.9062 39.738 97.9062 52C97.9062 64.262 93.1312 75.79 84.4606 84.4606Z"
                fill="#1371FB"
              />
              <rect x="48" y="27" width="8" height="35" fill="#1371FB" />
              <rect x="48" y="69" width="8" height="6" fill="#1371FB" />
            </svg>

            <MediumHeader>Regularna konserwacja</MediumHeader>
          </FoldableCardHeader>
          <Paragraph>
            Kompleksowo serwisujemy systemy klimatyzacji co znacznie przedłuża
            ich żywotność, i zapewnia lepszą wydajność.
          </Paragraph>
        </ServiceCard>
        <ServiceCard>
          <FoldableCardHeader>
            <svg
              width="104"
              height="104"
              viewBox="0 0 104 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M88.7697 15.2303C78.948 5.40902 65.8897 0 52 0C38.1103 0 25.052 5.40902 15.2303 15.2303C5.40902 25.052 0 38.1103 0 52C0 65.8897 5.40902 78.948 15.2303 88.7697C25.052 98.591 38.1103 104 52 104C65.8897 104 78.948 98.591 88.7697 88.7697C98.591 78.948 104 65.8897 104 52C104 38.1103 98.591 25.052 88.7697 15.2303ZM84.4606 84.4606C75.79 93.1312 64.262 97.9062 52 97.9062C39.738 97.9062 28.21 93.1312 19.5394 84.4606C10.8688 75.79 6.09375 64.262 6.09375 52C6.09375 39.738 10.8688 28.21 19.5394 19.5394C28.21 10.8688 39.738 6.09375 52 6.09375C64.262 6.09375 75.79 10.8688 84.4606 19.5394C93.1312 28.21 97.9062 39.738 97.9062 52C97.9062 64.262 93.1312 75.79 84.4606 84.4606Z"
                fill="#1371FB"
              />
              <path
                d="M48.2029 31V28.5C47.1904 28.5 46.2779 29.1107 45.8918 30.0466L48.2029 31ZM70 31L71.9669 32.5432C72.5574 31.7904 72.6666 30.7667 72.2481 29.9064C71.8296 29.046 70.9567 28.5 70 28.5V31ZM56.087 48.7333L54.1201 47.1902C53.5295 47.9429 53.4203 48.9666 53.8389 49.827C54.2574 50.6873 55.1302 51.2333 56.087 51.2333V48.7333ZM67.6812 48.7333L69.5487 50.3954C70.2034 49.6597 70.3652 48.6083 69.9621 47.7099C69.5589 46.8114 68.6659 46.2333 67.6812 46.2333V48.7333ZM39.8551 80L37.491 79.1868C37.1036 80.3132 37.5646 81.5565 38.5928 82.1579C39.621 82.7594 40.9307 82.5518 41.7226 81.662L39.8551 80ZM48.2029 55.7333L50.5669 56.5466C50.8297 55.7827 50.7072 54.9389 50.238 54.2813C49.7688 53.6237 49.0107 53.2333 48.2029 53.2333V55.7333ZM38 55.7333L35.6889 54.78C35.3708 55.5512 35.4583 56.4304 35.9223 57.1238C36.3864 57.8171 37.1657 58.2333 38 58.2333V55.7333ZM48.2029 33.5H70V28.5H48.2029V33.5ZM68.0331 29.4568L54.1201 47.1902L58.0538 50.2765L71.9669 32.5432L68.0331 29.4568ZM56.087 51.2333H67.6812V46.2333H56.087V51.2333ZM65.8136 47.0713L37.9875 78.338L41.7226 81.662L69.5487 50.3954L65.8136 47.0713ZM42.2191 80.8132L50.5669 56.5466L45.8389 54.9201L37.491 79.1868L42.2191 80.8132ZM48.2029 53.2333H38V58.2333H48.2029V53.2333ZM40.3111 56.6867L50.514 31.9534L45.8918 30.0466L35.6889 54.78L40.3111 56.6867Z"
                fill="#1371FB"
              />
            </svg>

            <MediumHeader>Obniż swoje rachunki</MediumHeader>
          </FoldableCardHeader>
          <Paragraph>
            Źle działająca klimatyzacja powoduje wzrost zużycia energii, co
            przekłada się na wyższe rachunki za prąd. Zadbaj o swój portfel.
          </Paragraph>
        </ServiceCard>
      </CardContainer>
    </>
  )
}
