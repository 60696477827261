import styled from "styled-components"

export const Wrapper = styled.div`
  max-width: 1040px;
  width: 100%;
  margin: 100px auto 60px auto;
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    max-width: 800px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 60px auto 30px auto;
  }
`
export const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const GalleryItem = styled.div`
  width: calc(50% - 20px);
  margin-bottom: 40px;
  &:not(:nth-child(2n)) {
    margin-right: 40px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    width: calc(50% - 10px);
    &:not(:nth-child(2n)) {
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 430px) {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0 !important;
  }
`
export const GalleryTitle = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 60px;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    font-size: 26px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    font-size: 18px;
  }
`
export const ImageBox = styled.div`
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
