import React from "react"
import { PageWrapper, OverflowWrapper } from "shared/Styles/Wrappers"
import { Car } from "components/Icons"
import {
  Box,
  Container,
  Background,
  Col,
  IconContainer,
  TitleContainer,
  DescriptionContainer,
  LinkContainer,
  ImageContainer,
  StyledLink,
  BackIconContainer,
} from "./styles"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { NormalHeader, Paragraph } from "components/Typography"

const Assembly = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      first: file(relativePath: { eq: "images/Offer/image40.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 670, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Box>
      <OverflowWrapper id={id}>
        <Background />
        <PageWrapper style={{ overflowY: "hidden" }}>
          <Container>
            <Col className={"background"}>
              <BackIconContainer>
                <svg
                  width="650"
                  height="652"
                  viewBox="0 0 650 652"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M637.13 327.67C603.294 213.461 507.521 129.964 398.824 119.8L394.685 119.582C379.873 119.582 366.004 126.842 357.509 138.968C332.386 174.98 314.306 244.101 313.217 305.235C287.295 319.102 254.33 330.792 228.553 335.657C213.741 274.886 233.926 205.692 282.72 154.142C341.607 92.1368 425.399 74.3485 507.013 106.803C530.248 115.952 556.605 104.625 565.827 81.3912C575.048 58.23 563.649 31.8014 540.413 22.5805C502.656 7.55112 464.028 0 425.69 0C347.198 0 273.063 32.6002 216.936 91.8465C134.959 178.247 110.49 302.911 156.016 402.018C162.841 416.83 177.145 426.922 193.338 428.302C237.121 432.005 305.738 413.127 359.324 383.359C384.012 398.751 410.296 421.186 427.505 441.225C382.341 484.426 312.273 501.561 243.293 485.007C160.155 465.04 102.866 401.292 90.1588 314.456C86.6009 290.205 63.511 272.707 38.751 276.192C26.7703 277.935 16.1691 284.252 8.98071 293.981C1.71971 303.71 -1.25728 315.69 0.485355 327.597C18.7831 452.407 101.704 544.18 222.164 573.078C337.977 600.886 458.219 559.718 521.244 470.703C530.684 457.417 532.208 439.991 525.31 425.252C506.795 385.682 456.258 335.657 404.052 304.146C404.996 275.321 411.24 241.414 420.244 215.639C480.147 233.21 529.958 285.268 550.143 353.3C574.468 435.272 547.82 516.735 478.985 571.19C459.381 586.727 456.113 615.261 471.579 634.865C480.51 646.119 493.798 652 507.158 652C517.033 652 526.908 648.805 535.186 642.271C634.298 564.074 672.346 446.453 637.13 327.67Z"
                    fill="#126BED"
                  />
                </svg>
              </BackIconContainer>
              <IconContainer>
                <Car width={"115px"} />
              </IconContainer>
              <TitleContainer>
                <NormalHeader color={"#fff"}>Montaż</NormalHeader>
              </TitleContainer>
              <DescriptionContainer>
                <Paragraph color={"#fff"}>
                  Kiedy zdecydujesz się&nbsp;na&nbsp;współpracę z&nbsp;nami,
                  nasi specjaliści zadbają, aby klimatyzacja działała
                  jak&nbsp;należy. Zajmujemy się kompleksowym montażem
                  we&nbsp;wszystkich pomieszczeniach przez cały rok. Oferujemy
                  sprzęt firm takich jak: Samsung, Aux, Gree, Rotenso, Haier,
                  Sevra oraz Toshiba.
                </Paragraph>
              </DescriptionContainer>
            </Col>
            <Col>
              <ImageContainer>
                <Image fluid={data.first.childImageSharp.fluid} />
              </ImageContainer>
            </Col>
          </Container>
        </PageWrapper>
      </OverflowWrapper>
    </Box>
  )
}

export { Assembly }
