import styled, { css } from "styled-components"
import { Link } from "gatsby"

const content = css`
  z-index: 1;
  margin-top: 32px;
  padding: 0 25px;
`
export const Box = styled.div`
  overflow: hidden;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 130px 0;
  }
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &.background {
    @media screen and (min-width: ${props =>
        props.theme.breakpoints.tabletSmall}) {
      width: 320px;
    }
    &::after {
      content: "";
      background: ${props => props.theme.color.primary};
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      @media screen and (min-width: ${props =>
          props.theme.breakpoints.tabletSmall}) {
        content: unset;
      }
    }
  }
`

export const IconContainer = styled.div`
  ${content}
`

export const TitleContainer = styled.div`
  ${content}
`

export const DescriptionContainer = styled.div`
  ${content}
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints.tabletSmall}) {
    padding-bottom: 30px;
  }
`

export const LinkContainer = styled.div`
  ${content}
  margin-bottom: 32px;
`

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    width: 440px;
  }
  &::after {
    content: "";
    width: 100%;
    height: 10px;
    background: ${props => props.theme.color.secondary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

export const Background = styled.div`
  display: none;
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    display: unset;
    &::after {
      content: "";
      background: ${props => props.theme.color.primary};
      width: 55vw;
      height: 1095px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.tabletBig}) {
    &::after {
      width: 65vw;
    }
  }
`

export const StyledLink = styled(Link)`
  color: ${props => props.theme.color.secondary};
  font-size: 16px;
  text-decoration: none;
`

export const BackIconContainer = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;

  svg {
    transform: translateY(-100px);
  }
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.tabletSmall}) {
    svg {
      transform: translateX(-300px);
    }
    overflow: unset;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.tabletBig}) {
    svg {
      transform: translateX(-50px);
    }
  }
`
