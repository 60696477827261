import { graphql, useStaticQuery } from "gatsby"

const useSliderPhotos = () => {
  const images = useStaticQuery(graphql`
    query {
      slide_2: file(relativePath: { eq: "images/Offer/gal1.png" }) {
        childImageSharp {
          fluid(maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide_1: file(relativePath: { eq: "images/Offer/slide1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return images
}

export { useSliderPhotos }
