import styled from "styled-components"
import { PageWrapper, OverflowWrapper } from "shared/Styles/Wrappers"
import { MediumHeader } from "components/Typography"

export const CustomOverflowWrapper = styled(OverflowWrapper)`
  background: white;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    align-items: center;
  }
  &::before {
    content: "";
    position: absolute;
    width: 35vw;
    height: 105%;
    top: 0;
    left: 0;
    background-color: #fafafa;
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletBig}) {
      height: 90%;
    }
    @media screen and (max-width: 650px) {
      height: 50%;
    }
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      display: none;
    }
  }
`

export const ConsultancyWrapper = styled(PageWrapper)`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    align-items: flex-start;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    margin-top: 20px;
    position: relative;
  }
`
export const ImageWrapper = styled.div`
  width: 55%;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    width: 50%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    height: 310px;
  }
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  &:after {
    position: absolute;
    content: " ";
    height: 18px;
    width: 100%;
    background: ${({ theme }) => theme.color.secondary};
    z-index: 2;
    bottom: 0;
    left: 0;
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.phoneBig}) {
      height: 10px;
    }
  }
`
export const TextWrapper = styled.div`
  margin-left: 80px;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    svg {
      height: 90px;
      margin-top: 0 !important;
    }
    margin-left: 40px;
    & > * {
      margin: 16px 0;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    svg {
      height: 70px;
    }
  }
  @media screen and (max-width: 650px) {
    margin-left: 0;
    padding: 85px 30px 55px 30px;
    max-width: unset;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phoneSmall}) {
    padding: 65px 10px 35px 10px;
  }
  .spacing-text {
    margin: 20px 0;
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletBig}) {
      margin: 16px 0;
    }
  }
`
export const ConsultancyHeader = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    margin: 0;
  }
  @media screen and (max-width: 650px) {
    flex-direction: row;
    align-items: center;
    ${MediumHeader} {
      font-size: 22px;
    }
    svg {
      margin-right: 20px !important;
    }
  }
`
